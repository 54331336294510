<template>
	<div class="table-summary position-relative w-100 h-100 d-flex flex-column">
		<div class="controls position-relative w-100 h-auto mb-3 d-flex justify-content-between">
			<search v-bind:fields="fields"
			        v-bind:placeholder="`Search ${title}`"
			        v-bind:filter-field.sync="filterField"
			        v-bind:search-keywords.sync="searchKeywords"
			        v-bind:style="{width: '600px !important'}"></search>
			<div class="table-buttons position-relative h-auto w-auto d-flex">
				<button type="button" class="button btn btn-custom-light w-auto ml-2 d-flex align-items-center overflow-hidden"
				        v-on:click="$emit('add-new')">
					<font-awesome-icon class="icon mr-2" v-bind:icon="['fas', 'plus']"></font-awesome-icon>
					<span class="name font-weight-bold text-truncate">New {{title}}</span>
				</button>
				<button type="button" class="button btn btn-custom-light w-auto ml-2 d-flex align-items-center overflow-hidden"
				        v-on:click="exportCSV">
					<font-awesome-icon class="icon mr-2" v-bind:icon="['fas', 'file-csv']"></font-awesome-icon>
					<span class="name font-weight-bold text-truncate">Export CSV</span>
				</button>
			</div>
		</div>
		<data-table v-bind:fields="fields"
		            v-bind:data-array="sortedFilteredArray"
		            v-bind:selectable="false"
		            v-bind:multi-selectable="false"
		            v-bind:selected-ids="[]"
		            v-on:sort-data="sortData($event)"
		            v-on:row-clicked="$emit('row-clicked', $event)"></data-table>
	</div>
</template>

<script>
import Search from "@/components/functional/table/Search";
import DataTable from "@/components/functional/table/DataTable";
import FileSaver from "file-saver";
import { toCsv } from "@/worker/file-export.worker";
import { sortArrayObj } from "@/utility/helpers"

export default {
	name: "TableSummary",

	props: {
		title: {
			type: String,
			required: true
		},
		filter: {
			type: String,
			required: true
		},
		sort: {
			type: String,
			required: true
		},
		order: {
			type: Number,
			required: true
		},
		fields: {
			type: Array,
			required: true
		},
		dataArray: {
			type: Array,
			required: true
		},
	},

	components: {
		Search,
		DataTable,
	},

	data() {
		return {
			searchKeywords: "",
			filterField: this.filter,
			sortField: this.sort,
			// 1: ascending, -1: descending
			sortOrder: this.order,
		}
	},

	computed: {
		filteredArray() {
			return this.dataArray.filter(
				dataObj => dataObj[this.filterField].toString().toLowerCase().includes(this.searchKeywords.toLowerCase())
			);
		},

		sortedFilteredArray() {
			// tackles vue reactivity caveats - array
			const arr = [...this.filteredArray]
			return sortArrayObj(arr, this.sortField, this.sortOrder);
		},
	},

	methods: {
		sortData(field) {
			if (this.sortField === field) {
				this.sortOrder = -this.sortOrder;
			} else {
				this.sortField = field;
				this.sortOrder = 1;
			}
		},

		async exportCSV() {
			const file = await toCsv(this.sortedFilteredArray);
			FileSaver.saveAs(file, `${this.title}.csv`);
		},
	}
}
</script>

<style lang="scss" scoped>
.table-buttons {
	min-width: 0;
}
</style>
